import React, { useEffect, useState, useRef } from "react";
import styled from 'styled-components';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { openPopupWindow, formatNumber, loader } from 'helpers/generic';
import { getRouterBasename } from "helpers/config";
import { default as BankReconciliationService } from 'services/BankReconciliation';

const ModalStyled = styled.div`
	background: rgba(0,0,0, 0.7);
`;

const Loader = styled.div`
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
`;

let newInvoiceWindow = null;
let saveSuccessSwitch = false;

export default function TradeCategoryModal(props) {
	const modalRef = useRef(null);

	const data = props.data;
	const closeCallback = props.closeCallback;

	const [creatingInvoice, setCreatingInvoice] = useState(false);
	const [loading, setLoading] = useState(false);

	let entity = data.reconciliation[0].entity;

	const hideModal = () => {
		const modal = Modal.getInstance(modalRef.current);
		if ( modal ) modal.hide();
	}

	useEffect(() => {

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
			if ( e.target !== modal._element ) return; // Fix to avoid close when a child modal is closed

           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			closeCallback(saveSuccessSwitch);

			// Close new invoice window if modal is closed and new window is opened
			if ( newInvoiceWindow && !newInvoiceWindow.closed ) newInvoiceWindow.close();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);
		modal.show();
	}, [closeCallback]);

	useEffect(() => {
		window.PopupProxyCallback = (newInvoice) => {
            if ( newInvoice?.id ) {
				updateBankReconciliation(newInvoice);
			}
			setCreatingInvoice(false);
		}

		return function cleanup() {
			window.PopupProxyCallback = null;
		}
	}, []);

	const updateBankReconciliation = async (invoice) => {
		setLoading(true);

		saveSuccessSwitch = false;

		// Delete previous
		let result = await BankReconciliationService.remove(data.reconciliation[0].bank_reconciliation_id);
		if ( !result ) {
			toast.error('Ha ocurrido un error al guardar');
			return;
		}

		// Create new reconciliation
		let movements = []
		movements.push({
			class: (() => {
				let entityClass = '';
				if ( entity.type === 'incomes' ) entityClass = 'Invoice';
				if ( entity.type === 'expenses' ) entityClass = 'ProviderInvoice';
				return 'App\\Models\\' + entityClass;
			})(),
			concept: invoice.number,
			id: invoice.id,
			amount: invoice.total
		});
		movements.push({
			class: 'App\\Models\\BankMovement',
			concept: data.concept,
			id: data.id,
			amount: data.amount,
			date: data.date,
		});


		let reconciliationType = '';
		if ( entity.type === 'incomes' ) reconciliationType = 'invoice';
		if ( entity.type === 'expenses' ) reconciliationType = 'provider-invoice';

		let saveBankReconciliationResult = await BankReconciliationService.save(reconciliationType, movements);
		if ( saveBankReconciliationResult ) {
			toast.success('Movimientos conciliados');
		} else {
			toast.error('Ha ocurrido un error al guardar');
		}

		saveSuccessSwitch = saveBankReconciliationResult;

		// console.log(saveSuccessSwitch);

		hideModal();
	}

	const createNewInvoice = () => {	
		if ( entity.type === 'incomes' ) {
			newClientInvoice();
		}

		if ( entity.type === 'expenses' ) {
			newProviderInvoice();
		}

		setCreatingInvoice(true);
	}

	const newClientInvoice = () => {
		newInvoiceWindow = openPopupWindow(
			getRouterBasename() + '/invoices/add?popup=true' + 
			'&page_title=Convertir categoría a factura' +
			'&date_reception=' + moment(data.date).format('YYYY-MM-DD') +
			'&amount=' + (data.amount * -1) +
			'&concept=' + data.concept +
			'&hidden_sections=payments,save_button' +
			'&fixed_total=' + (data.amount * -1) + 
			'&category_id=' + entity.id
		);
	}

	const newProviderInvoice = () => {
		newInvoiceWindow = openPopupWindow(
			getRouterBasename() + '/providers-invoices/add?popup=true' + 
			'&page_title=Convertir categoría a factura' +
			'&date_reception=' + moment(data.date).format('YYYY-MM-DD') +
			'&amount=' + (data.amount * -1) +
			'&concept=' + data.concept +
			'&hidden_sections=payments,save_button' +
			'&fixed_total=' + (data.amount * -1) + 
			'&category_id=' + entity.id
		);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Detalles de la categoría</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						{ creatingInvoice &&
							<div className="col-md-12 mt-3 text-center">
								Esperando la creación de la factura en la ventana abierta... 
								<Loader>{loader}</Loader>
							</div>
						}

						{ !creatingInvoice &&
							<table className="table table-sm table-bordered">
								<thead>
									<tr>
										<td colSpan="100%">
											Categorías
										</td>
									</tr>
								</thead>
								<tbody>
									{data.reconciliation.map((entity, index) => (
										<tr key={index}>
											<td>
												<div className="d-flex justify-content-between">
													<span>{entity.entity.concept}</span>
													{/* <button className="btn btn-sm btn-link p-0" onClick={() => openEstimateWindow(entity.entity.id)}> <i className="bi bi-eye"></i> </button> */}
												</div>
											</td>
											<td className="text-end">{formatNumber(entity.entity.amount, 2)}</td>
										</tr>
									))}
								</tbody>
							</table>
						}
					</div>
					{ !creatingInvoice && 
						<div className="modal-footer">
							<button className="btn btn-primary text-white" onClick={() => createNewInvoice()} disabled={loading}>Convertir a factura</button>
						</div>
					}
				</div>
			</div>
		</ModalStyled>
	);
}


