import React, { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getUser, setUser, checkRole } from 'helpers/user';
import { getConfig, getAjaxUrl } from 'helpers/config';
import { getBusiness, setBusiness } from 'helpers/business';
import useStore from "store";

import { default as BusinessesService } from 'services/Businesses';
import { default as AuthService } from 'services/Auth';

import ClearHistory from 'components/ClearHistory';

import Login from "pages/Login";
import Logout from "pages/Logout";
import ResetPassword from "pages/ResetPassword";

import Dashboard from "pages/Dashboard";
import Users from "pages/Users";
import UsersForm from "pages/UsersForm";
import Businesses from "pages/Businesses";
import BusinessesForm from "pages/BusinessesForm";
import CustomFields from "pages/CustomFields";
import CustomFieldsForm from "pages/CustomFieldsForm";
import Documents from "pages/Documents";
import DocumentViewer from "pages/DocumentViewer";
import Clients from "pages/Clients";
import ClientsForm from "pages/ClientsForm";
import Taxes from "pages/Taxes";
import TaxesForm from "pages/TaxesForm";
import InvoicesSeries from "pages/InvoicesSeries";
import InvoicesSeriesForm from "pages/InvoicesSeriesForm";
import TradeCategories from "pages/TradeCategories";
import TradeCategoriesForm from "pages/TradeCategoriesForm";
import Invoices from "pages/Invoices";
import InvoicesForm from "pages/InvoicesForm";
import PaymentMethods from "pages/PaymentMethods";
import PaymentMethodsForm from "pages/PaymentMethodsForm";
import Receipts from "pages/Receipts";
import ReceiptsForm from "pages/ReceiptsForm";
import Treasury from "pages/Treasury";
import TreasuryForm from "pages/TreasuryForm";
import BankRemittances from "pages/BankRemittances";
import Providers from "pages/Providers";
import ProvidersForm from "pages/ProvidersForm";
import Expenses from "pages/Expenses";
import ExpensesForm from "pages/ExpensesForm";
import ProvidersInvoices from "pages/ProvidersInvoices";
import ProvidersInvoiceForm from "pages/ProvidersInvoiceForm";
import ProvidersInvoiceDraft from "pages/ProvidersInvoicesDraft";
import BankReconciliation from "pages/BankReconciliation";
import BankReconciliationMovements from "pages/BankReconciliationMovements";
import Previsions from "pages/Previsions";
import PrevisionsForm from "pages/PrevisionsForm";
import BalanceGeneral from "pages/Balances/General";

const MainContainer = styled.div`
	
`;

function App() {
	const location = useLocation();

    const forceReload = useStore(state => state.forceReload);

	// Axios global
    axios.defaults.baseURL = getAjaxUrl();
	axios.defaults.headers.common = {
		'Authorization': getUser() ? 'Bearer ' + getConfig()?.token : null,
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'Expires': '0',
	};
    
    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error or csrf token mismatch
        if ( error.response && (error.response.status === 401 || error.response.status === 419 || error.response.status === 403) ) {
            setUser(null, true);
        }

        // Default
        return Promise.reject(error);
    });

	// Get user & community and update
    useEffect(() => {
    	const updateBusiness = async () => {
    		// Get selected business
	    	let business = getBusiness();
	    	if ( !business || !business.id || business.from_query_param ) return;

	    	// Get from server
	    	let business_from_server = await BusinessesService.get(business.id);

	    	// Compare and update
	    	if ( JSON.stringify(business) !== JSON.stringify(business_from_server) ) {
	    		setBusiness(business_from_server, true, false);
	    	}
    	} 
    	updateBusiness();

    	const updateLoggedUser = async () => {
    		// Get logged user
	    	let user = getUser();
	    	if ( !user || !user.id ) return;

	    	// Get from server
	    	let user_from_server = await AuthService.getAuthData();

	    	// Compare and update
	    	if ( JSON.stringify(user) !== JSON.stringify(user_from_server) ) {
	    		setUser({...user_from_server}, true);
	    	}
    	} 
    	updateLoggedUser();
    }, [forceReload]);

	return (
		<MainContainer id="main-container" forceReload={forceReload}>
			<ClearHistory />

			{ (!getUser() && (location.pathname !== '/login' && location.pathname !== '/reset-password' )) &&
				<Navigate to="/login" />
			}

			<Routes>
				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />
				<Route path="reset-password" element={<ResetPassword />} />

				<Route path="/" element={<Dashboard />} />

				{ checkRole('admin') &&
					<>
						<Route path="/users" element={<Users />} /> 
						<Route path="/users/add" element={<UsersForm />} /> 
						<Route path="/users/edit/:id" element={<UsersForm />} /> 

						<Route path="/businesses" element={<Businesses />} /> 
						<Route path="/businesses/add" element={<BusinessesForm />} /> 
					</>
				}
				<Route path="/businesses/edit/:id" element={<BusinessesForm />} /> 

				<Route path="/custom-fields/:type" element={<CustomFields />} /> 
				<Route path="/custom-fields/:type/add" element={<CustomFieldsForm />} /> 
				<Route path="/custom-fields/:type/edit/:id" element={<CustomFieldsForm />} /> 

				<Route path="/documents" element={<Documents />} /> 
				<Route path="/documents/view-file/:id" element={<DocumentViewer />} /> 
				
				<Route path="/clients" element={<Clients />} /> 
				<Route path="/clients/add" element={<ClientsForm />} /> 
				<Route path="/clients/edit/:id" element={<ClientsForm />} /> 

				<Route path="/taxes" element={<Taxes />} /> 
				<Route path="/taxes/add" element={<TaxesForm />} /> 
				<Route path="/taxes/edit/:id" element={<TaxesForm />} /> 

				<Route path="/invoices-series" element={<InvoicesSeries />} /> 
				<Route path="/invoices-series/add" element={<InvoicesSeriesForm />} /> 
				<Route path="/invoices-series/edit/:id" element={<InvoicesSeriesForm />} /> 

				<Route path="/trade-categories/incomes" element={<TradeCategories type="incomes" />} /> 
				<Route path="/trade-categories/incomes/add" element={<TradeCategoriesForm type="incomes" />} /> 
				<Route path="/trade-categories/incomes/edit/:id" element={<TradeCategoriesForm type="incomes" />} /> 

				<Route path="/trade-categories/expenses" element={<TradeCategories type="expenses" />} /> 
				<Route path="/trade-categories/expenses/add" element={<TradeCategoriesForm type="expenses" />} /> 
				<Route path="/trade-categories/expenses/edit/:id" element={<TradeCategoriesForm type="expenses" />} /> 

				<Route path="/payment-methods" element={<PaymentMethods />} /> 
				<Route path="/payment-methods/add" element={<PaymentMethodsForm />} /> 
				<Route path="/payment-methods/edit/:id" element={<PaymentMethodsForm />} /> 

				<Route path="/invoices" element={<Invoices />} /> 
				<Route path="/invoices/add" element={<InvoicesForm />} /> 
				<Route path="/invoices/edit/:id" element={<InvoicesForm />} />

				<Route path="/receipts" element={<Receipts />} /> 
				<Route path="/receipts/add" element={<ReceiptsForm />} /> 
				<Route path="/receipts/edit/:id" element={<ReceiptsForm />} />

				<Route path="/treasury" element={<Treasury />} /> 
				<Route path="/treasury/add" element={<TreasuryForm />} /> 
				<Route path="/treasury/edit/:id" element={<TreasuryForm />} />

				<Route path="/bankremittances" element={<BankRemittances />} /> 

				<Route path="/providers" element={<Providers />} /> 
				<Route path="/providers/add" element={<ProvidersForm />} /> 
				<Route path="/providers/edit/:id" element={<ProvidersForm />} /> 

				<Route path="/expenses" element={<Expenses />} /> 
				<Route path="/expenses/add" element={<ExpensesForm />} /> 
				<Route path="/expenses/edit/:id" element={<ExpensesForm />} /> 

				<Route path="/providers-invoices" element={<ProvidersInvoices />} /> 
				<Route path="/providers-invoices/add" element={<ProvidersInvoiceForm />} /> 
				<Route path="/providers-invoices/edit/:id" element={<ProvidersInvoiceForm />} /> 
				<Route path="/providers-invoices/draft" element={<ProvidersInvoiceDraft />} /> 

				<Route path="/bank-reconciliation" element={<BankReconciliation />} /> 
				<Route path="/bank-reconciliation/:id/movements" element={<BankReconciliationMovements />} />

				<Route path="/previsions" element={<Previsions />} /> 
				<Route path="/previsions/add" element={<PrevisionsForm />} />
				<Route path="/previsions/edit/:id" element={<PrevisionsForm />} />

				<Route path="/balances/general" element={<BalanceGeneral />} />
			</Routes>
			<ToastContainer
				position="top-right"
				autoClose={2000}
			/>
		</MainContainer>
	);
}

export default App;