import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getBusiness } from 'helpers/business';
import EmpoweredSelector from 'components/EmpoweredSelector';
import CustomInput from 'components/CustomInput';
import {default as TradeCategoriesService} from 'services/TradeCategories';
import BusinessChecker from 'components/BusinessChecker';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function TradeCategoriesForm(props) {
	const type = props.type;
	const typeName = props.type === 'incomes' ? 'ingresos' : 'gastos';

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const parent_id = queryParams.get('parent_id');
	const parent_readonly = queryParams.get('parent_readonly') === 'true';
	const hide_parent_field = queryParams.get('hide_parent_field') === 'true';

	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				let categories = await TradeCategoriesService.get(type, params.id);
				if ( categories ) {
					setData({...categories});
				}

				setLoading(false);
			}
			getData();
		}
	}, [type, params.id]);


	// Get parent if needed
	useEffect(() => {
		if ( !parent_id ) return;

		const getParent = async () => {
			setLoading(true);
			setData([]);

			let parent = await TradeCategoriesService.get(type, parent_id, {with_next_child_code: true});
			if ( parent ) {
				setData((prev) => ({
					...prev, 
					parent: parent, 
					parent_id: parent.id, 
					code: parent.nextChildCode
				}));

				document.querySelector('#name').focus();
			}

			setLoading(false);
		}
		getParent();
	}, [parent_id]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data, 'trade-category');
		};
	}, [data]);

	const loadCategories = async (input, callback) => {
		let categories = await TradeCategoriesService.listOrdered(type, {
			search: input
		});

		let formatted = categories.map((el) => {
			return {
				value: el, 
				label: (
					<div style={{paddingLeft: ((el.level-1) * 8) + 'px'}}>
						{el.level > 1 &&
							<>
								<i className="bi bi-arrow-return-right"></i>
								&nbsp;
							</>
						}
                    	<span className="fw-bold text-dark me-1">{el.code}</span>
						{el.name}
					</div>
				)
			};
		});

		callback(formatted);
	}

	const deleteCategory = async () => {
		const c = window.confirm('¿Quieres eliminar esta categoría?');
		if ( !c ) return;

		let result = await TradeCategoriesService.delete(type, data.id);
		if ( result ) {
			toast.info('Categoría borrada')
	    	navigate('/trade-categories/' + type);
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const saveData = async (goBack = true) => {
		setErrors({});
		setLoading(true);

		data.business_id = getBusiness()?.id;

		let result = null;
		try {
			result = await TradeCategoriesService.save(type, data);
		} catch (e) {}

		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Datos guardados');
			setData({...result.category});

			if ( popup && goBack ) {
				if ( parentWindowProxyCallback ) data = result.category; // Fix because parent needs id and update state function on previous line from this line update state async
				window.close();
				return;
			}

			if ( goBack ) navigate('/trade-categories/' + type);
			else {
		    	navigate('/trade-categories/' + type + '/edit/' + result.category.id + (popup ? '?popup=true' : ''));
			}
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<BusinessChecker />

			{ loading && <CristalLoader /> }			
			<section>
				<div className="page-title">
					<h1>Ficha de categoría de {typeName}</h1>
					<button onClick={() => popup ? window.close() : navigate('/trade-categories/' + type)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										{ !hide_parent_field && 
											<div className="col-lg-4">
												<div className="mb-2">
													<EmpoweredSelector
														load={loadCategories}
														onChange={(value) => setData((prev) => ({...prev, parent: value, parent_id: value?.id}))}
														timeout={250}
														label={
															data.parent?.id ?
																<div>
																	<b className="me-1">{data.parent?.code}</b>  
																	<span>{data.parent?.name}</span>
																</div>
															: null
														}
														placeholder="Categoría superior"
														showPlaceholderHelper={true}
														value={data.parent?.id}
														// disabled={parent_readonly || data.id}
														disabled={parent_readonly || parent_id}
													/>
													{ errors.parent_id &&
														<div className="invalid-feedback d-block">{ errors.parent_id[0] }</div>
													}
												</div>
											</div>
										}
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput 
													label="Código" 
													type="text" 
													className="form-control form-control-sm" 
													onChange={(e) => setDataField('code', e.target.value)} 
													value={data.code ?? ''} 
													// readOnly={data.id ? true : false} 
												/>

												{ errors.code &&
													<div className="invalid-feedback d-block">{ errors.code[0] }</div>
												}
											</div>
										</div>
										<div className={'col-lg-' + (hide_parent_field ? '8' : '4')}>
											<div className="mb-2">
												<CustomInput label="Nombre" type="text" id="name" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && !data.is_used) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteCategory()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}