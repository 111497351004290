import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import TrSkeleton from 'components/TrSkeleton';
import { formatNumber, jsonToArray, loader, openPopupWindow } from 'helpers/generic';
import { default as BalancesService } from 'services/Balances';
import { getRouterBasename } from 'helpers/config';

const Table = styled.table`
	& > thead > tr,
	& > tbody > tr {
		&.level-1 {
			* {
				font-weight: bold !important;
				font-size: 13px;
			}
		}

		&.has-movements {
			cursor: pointer;
            user-select: none;

			&:hover {
				td {

					&:nth-child(1) {

						i {
							color: var(--bs-primary);
						}
					}
				}
			}
		}

		td {
			&:nth-child(1) {

				b {
					font-weight: 400;
				}

				i.mark {
					background: yellow;
					font-style: normal;
					padding: 0;
				}
			}

			&:nth-child(2) {
				width: 120px;
				text-align: right;

				img {
					display: block;
					margin: 0 auto;
					width: 20px;
				}
			}
		}

		th {
			position: relative;
			height: 40px;
			background: var(--bs-light);

			.table-header-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: space-between;
				align-items: center;

				padding: 5px 10px;

				font-size: 16px;

				.search-wrapper {
					width: 100%;
					height: 25px;
					
					width: 180px;

					input {
						background: white;
						border: 1px solid var(--bs-gray-200);
						padding: 0px 5px;
						height: 20px;
						min-height: auto;
						height: 100%;
					}
				}
			}
		}
	}
`;

const MovementsContainer = styled.tr`
    display: none;

    &:hover {
        * {
            box-shadow: unset !important;

        }
    }

    &.opened {
        display: table-row;
    }

    & > td {
        padding: 0;

        & > div {
            border-left: var(--paddingLeft) solid var(--bs-gray-400);

            #loader {
                width: 50px;
            }
        }
    }

    table.table-movements {
        margin-bottom: 0;

        &:not(.first-child) {
            border-top: 1px solid var(--bs-gray-300);
        }

        tbody {
            border-top: unset !important;

            tr {

                &:last-child {
                    border-bottom-color: transparent !important;
                }
            }
        }

        th {
            font-weight: 500;
        }

        th, td {
            height: auto !important;

            &:nth-child(1) {
                width: 100px;
                border-right: 1px solid var(--bs-gray-300);
            }

            &:nth-child(2) {
                text-align: left;
                width: unset;
                border-right: 1px solid var(--bs-gray-300);

                i {
                    cursor: pointer;
                    color: var(--bs-primary);
                }
            }

            &:nth-child(3) {
                width: 100px;
                text-align: right;
            }
        }
    }
`;

export default function (props) {
    const title = props.title;
    const search = props.search;
    const setSearch = props.setSearch;
    const categories = props.categories;
    const categoriesFilteredIds = props.categoriesFilteredIds;
    const balances = props.balances;
    const type = props.type;
    const dateFilters = props.dateFilters;
    const hasMovements = props.hasMovements;

    const [movementsOpened, setMovementsOpened] = useState([]);
    const [movements, setMovements] = useState({});

    const toggleMovementsContainer = (key) => {
		let newMovementsOpened = [...movementsOpened];
		let idx = newMovementsOpened.indexOf(key);

		if ( idx !== -1 ) {
			newMovementsOpened.splice(idx, 1);
            removeMovements(key);
		} else {
			newMovementsOpened.push(key);
            loadMovements(key);
		}

		setMovementsOpened(newMovementsOpened);
    }

    const loadMovements = async (id) => {
        // Set loading
        let newMovements = {...movements};
        newMovements[id] = {loading: true};
        setMovements(newMovements);

        // Load data
        let data = await BalancesService.generalListTradeCategoryMovements(type, id, {year: dateFilters.financialYear, quarter: dateFilters.quarter, month: dateFilters.month});
        addMovements(id, data);
    }

    const addMovements = (key, data) => {
        let newMovements = {...movements};
        newMovements[key] = data;
        setMovements(newMovements);
    } 

    const removeMovements = (key) => {
        let newMovements = {...movements};
        delete newMovements[key];
        setMovements(newMovements);
    }

    const openMovementEntity = (movement, entityType) => {
        let url = getRouterBasename();

        if ( entityType === 'bankmovements' ) url += '/bank-reconciliation/' + movement.treasury_id + '/movements?popup=true';
        if ( entityType === 'invoices' ) url += '/invoices/edit/' + movement.id + '?popup=true';   
        if ( entityType === 'expenses' ) url += '/expenses/edit/' + movement.id + '?popup=true';   
        if ( entityType === 'providerinvoices' ) url += '/providers-invoices/edit/' + movement.id + '?popup=true';   
        if ( entityType === 'bankremittances' ) url += '/bankremittances?popup=true';   

        openPopupWindow(url);
    }
    
    return (
        <>
            <div className="table-responsive table-responsive-carded">
                <Table className="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th colSpan={'100%'}>
                                <div className="table-header-wrapper">
                                    {title}

                                    <div className="search-wrapper">
                                        <input type="search" className="form-control form-control-sm" placeholder="Buscar" value={search ?? ''} onChange={(e) => setSearch(e.target.value)} />
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        { categories?.length > 0 &&
                            categories.filter(el => categoriesFilteredIds.includes(el.id)).map((el, idx) => {
                                let categoryName = el.code + ' ' + el.name;
                                let paddingLeft = (el.level * 15);
                                let existsMovements = hasMovements && hasMovements[el.id] ? true : false;

                                // Replace coincidence from incomeSearch on name using <i> tag
                                if ( el.search ) {
                                    let search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                                    let code = el.code.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                                    let name = el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                                    let nameLower = (code + ' ' + name);
                                    let start = nameLower.indexOf(search);
                                    let end = start + search.length;
                                    if ( start !== -1 ) {
                                        categoryName = (
                                            <>
                                                {categoryName.substring(0, start)}
                                                <i className="mark">{categoryName.substring(start, end)}</i>
                                                {categoryName.substring(end)}
                                            </>
                                        );
                                    }
                                }

                                return ( 
                                    <React.Fragment key={idx}>
                                        <tr className={(el.level === 1 ? 'level-1' : '') + ' ' + ((existsMovements ?? null) ? 'has-movements' : '')} onClick={() => existsMovements ? toggleMovementsContainer(el.id) : null}>
                                            <td style={{paddingLeft: paddingLeft + 'px'}}>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        {el.parent && 
                                                            <>
                                                                <i className="bi bi-arrow-return-right"></i>
                                                                &nbsp;
                                                            </>
                                                        }
                                                        {categoryName}
                                                    </div>
                                                    {existsMovements &&
                                                        <>
                                                            {movementsOpened.indexOf(el.id) !== -1 && <i className="bi bi-caret-up-fill"></i>}
                                                            {movementsOpened.indexOf(el.id) === -1 && <i className="bi bi-caret-down-fill"></i>}
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {(!balances || balances[el.id] === undefined) && loader}
                                                {(balances && balances[el.id] !== undefined) && formatNumber(balances[el.id].total, 2)}
                                            </td>
                                        </tr>
                                        {existsMovements &&
                                            <MovementsContainer className={'movements-container ' + (movementsOpened.indexOf(el.id) !== -1 ? 'opened' : 'closed')} style={{'--paddingLeft': paddingLeft + 'px'}}>
                                                <td colSpan="100%">
                                                    <div>
                                                        {movements[el.id] && movements[el.id].loading && loader}
                                                        {movements[el.id] && movements[el.id].length === 0 && <div className="text-center">No hay movimientos</div>}
                                                        {movements[el.id] && movements[el.id].loading === undefined && 
                                                            <>
                                                                {jsonToArray(movements[el.id]).filter(entity => entity.value.movements.length > 0).map((entity, idx) => {
                                                                    return (
                                                                        <div key={entity.key}>
                                                                            <table className={'table table-sm table-movements ' + (idx === 0 ? 'first-child' : '')}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th colSpan="100%">{entity.value.title}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {entity.value.movements.map((movement, idx) => {

                                                                                        return (
                                                                                            <tr key={idx}>
                                                                                                <td>{moment(movement.date).format('DD-MM-YYYY')}</td>
                                                                                                <td>{movement.name} <i className="bi bi-eye" onClick={() => openMovementEntity(movement, entity.key)}></i></td>
                                                                                                <td>{formatNumber(movement.amount, 2)}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </>
                                                        }
                                                    </div>
                                                </td>
                                            </MovementsContainer>
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                        { categories?.length === 0 &&
                            <TrSkeleton rows={10} columns={2} />
                        }
                    </tbody>
                </Table>
            </div>
        </>
    );
}