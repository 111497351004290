import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { default as FilesService } from 'services/Files';

const TreeRowStyled = styled.div`

`;

const TreeLinkStyled = styled.div`
	display: flex;
	padding-left: ${props => props.level ? props.level * 5 : 0}px;

	&:hover {
		background: var(--bs-gray-300);
		cursor: pointer;
	}

	&.selected {
		color: var(--bs-primary);
	}

	.btn-dropdown-content {
		display: inline-flex;
		align-items: start;
		min-width: 20px;

		button {
			background: none;
			height: auto;
			min-height: auto;
			padding: 0;
			border: 0;
			width: 100%;
		}
	}

	.icon {
		display: inline-flex;
		min-width: 20px;
		margin-left: 5px;

		i.bi-folder-fill {
			color: var(--bs-orange);
		}
	}
		
	.name {
		display: inline-flex;
		user-select: none;
		padding: 4px 0px;
		line-height: 15px;
	}
`;

const TreeContentStyled = styled.div`

`;

export default function TreeRow(props) {
    let business = props.business;
    let folder = props.folder;
    let level = props.level;
    let selectedFolderId = props.selectedFolderId;
    let openFolder = props.openFolder;
    let reload = props.reload;
    
    let [opened, setOpened] = useState(props.folder?.id === -1 || props.folder?.id === business?.folder_id ? true : false);
    let [tree, setTree] = useState([]);

    useEffect(() => {
        if ( !opened || !folder.has_content ) {
            return;
        }

        // Load subfolders
		const getFolderTree = async () => {
            // axiosCancelToken = axios.CancelToken.source(); // Fix: need to regenerate if not, cant open for second time | LO DEJO AQUÍ POR SI ACASO

            let result = await FilesService.getFolderTree(folder.id !== -1 ? folder.id : null, business?.id);

            if ( result ) {
                setTree(result);
            }
		}
		getFolderTree();
	}, [reload, business, opened, folder.id, folder.has_content]);

    const dropdownClick = (e, status, open = false) => {
		if ( e ) e.stopPropagation();

        setOpened(status);
        if ( open ) openFolder(folder);
        if ( !status ) setTree([]);
	}

    return (
        <TreeRowStyled>
            <TreeLinkStyled className={selectedFolderId === folder.id ? 'selected' : ''} level={level} onClick={(e) => dropdownClick(e, true, true)}>
                <div className="btn-dropdown-content">
                    { folder.has_content &&
                        <React.Fragment>
                            { !opened ?
                                <button onClick={(e) => dropdownClick(e, true)}><i className="bi bi-caret-right"></i></button>
                                :
                                <button onClick={(e) => dropdownClick(e, false)}><i className="bi bi-caret-down"></i></button>
                            }
                        </React.Fragment>
                    }
                </div>
                <div className="icon">
                    <i className="bi bi-folder-fill"></i>
                </div>
                <div className="name">{folder.name}</div>
            </TreeLinkStyled>

            
            <TreeContentStyled>
                { (opened && tree.length > 0) && 
                    tree.map((el, idx) => {
                        return (
                            <TreeRow 
                                key={idx}

                                folder={el}
                                openFolder={openFolder}
                                selectedFolderId={selectedFolderId}
                                business={business}
                                reload={reload}
                                level={level+1}
                            />
                        )
                    })
                }
            </TreeContentStyled>
        </TreeRowStyled>
    );
}