import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';
import { Modal } from 'bootstrap/dist/js/bootstrap';

const ModalStyled = styled.div`
    .modal {
	    background: rgba(0, 0, 0, 0.4);

        .modal-body {

            #recurrence-period-wrapper {
                color: var(--bs-primary);
                font-size: 12px;

                input[type=number] {
                    display: inline-block;

                    &#amount {
                        width: 100px;
                    }

                    &#period {
                        width: 20px;
                    }
                }

                select {
                    display: inline-block;
                    width: 120px;
                    margin: 0 5px;
                }
            }

        }
    }
`;

const FamilyRowModal = (props) => {
	const modalRef = useRef(null);

    const data = props.data;
    const closeCallback = props.closeCallback;

    const [newData, setNewData] = useState({...data});

    const [period, _setPeriod] = useState(1);
    const setPeriod = (period) => {
        if ( period < 1 ) period = 1;
        if ( period > 6 ) period = 6;
        _setPeriod(period);
    }
    const [monthStart, setMonthStart] = useState(1);
    const [amount, setAmount] = useState(false);

    useEffect(() => {
        const modal = new Modal(modalRef.current, {
			backdrop: false,
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';

            closeCallback();
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

        modal.show();
    }, [closeCallback]);

    const closeModal = (dataToSave = null) => {
        if ( dataToSave ) {
            // If recurrent calc months
            let months = {};
            for(let i = 1; i <= 12; i++) {
                months[i] = {
                    amount: ''
                };
            }

            let counterToSet = 0;
            for (let i = monthStart; i <= 12; i++) {
                if ( i === monthStart || counterToSet === 0 ) {
                    months[i].amount = amount;
                    counterToSet = period;
                }
                counterToSet--;
            }

            dataToSave.monthsAmount = months;
        }

        const modal = Modal.getInstance(modalRef.current);
        modal.hide();

        closeCallback(dataToSave);
    }

    const backdropCloseModal = (e) => {
        if ( e.target === modalRef.current ) closeModal(null);
    }

    return (
        <ModalStyled>
            <div className="modal" tabIndex="-1" ref={modalRef} onMouseDown={(e) => backdropCloseModal(e)}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Actualizar previsión</h5>
                            <button type="button" className="btn-close" onClick={() => closeModal(null)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div id="recurrence-period-wrapper">
                                <input id="amount" label="Importe" type="number" className="form-control form-control-sm text-end no-arrows" onChange={(e) => setAmount(e.target.value)} value={amount ?? ''} />€

                                cada 
                                <input 
                                    id="period"
                                    type="number" 
                                    className="form-control form-control-sm no-arrows" 
                                    defaultValue={period ?? ''} 
                                    onFocus={(e) => {
                                        e.target.select();
                                    }}
                                    onKeyDown={(e) => {
                                        e.target.value = '';
                                    }}
                                    onChange={(e) => {
                                        e.preventDefault();

                                        let value = parseInt(e.target.value);
                                        if ( !isNaN(value) ) {
                                            if ( value < 1 ) value = 1;
                                            if ( value > 6 ) value = 6;
                                            e.target.value = value;
                                            setPeriod(value);
                                        } else {
                                            e.preventDefault();
                                        }
                                    }}
                                /> 
                                meses empezando en
                                <select className="form-control form-control-sm" value={monthStart} onChange={(e) => setMonthStart(e.target.value)}>
                                    {Array.from({length: 12}, (v, k) => k + 1).map((month) => {
                                        return <option key={month} value={month}>{moment('2024-' + (month < 10 ? '0' : '') + month + '-01').locale('es').format('MMMM')}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <button type="button" className="btn btn-sm btn-primary text-white ms-auto" onClick={() => closeModal(newData)}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalStyled>
    );
}

export default FamilyRowModal;