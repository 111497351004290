import styled from "styled-components";
import { NavLink } from "react-router-dom";
import EmpoweredSelector from "components/EmpoweredSelector";
import CustomInput from "components/CustomInput";
import LabeledFrame from "components/LabeledFrame";
import { default as ClientsService } from 'services/Clients';
import { openPopupWindow } from "helpers/generic";
import { useEffect } from "react";

const ClientsSelector = styled.div` 
    display: flex;
    align-items: center;

    .empowered-selector {
       
        .empowered-selector_label {
            font-weight: inherit;
            padding: 0;
            background: transparent;
            font-size: inherit;
            min-height: auto;

            .dropdown-toggle-icon {
                position: unset;
                margin-left: 10px;
                margin-top: 5px;
            }
        }
    }

    & > button {
        border: 0;
        padding: 0;
        background: none;
        font-size: 15px;
        line-height: 0;
        margin-top: 5px;
        margin-left: 10px;

        &:hover {
            transform: scale(1.2);
        }
    }
`;

export default function ClientPanel(props) {
    let data = props.data;
    let setData = props.setData;
    let setDataField = props.setDataField;
    let errors = props.errors;
    let canEdit = props.readOnly ? false : true;

    useEffect(() => {
        let initialPopupProxyCallback = window.PopupProxyCallback;

        window.PopupProxyCallback = (data) => {
            if ( data && data.id ) {
                setData({
                    id: data.id,
                    name: data.name,
                    vatnumber: data.vatnumber,
                    address: data.address,
                    postalcode: data.postalcode,
                    city: data.city,
                    province: data.province,
                    country: data.country
                });
            }
        }

        return () => {
            window.PopupProxyCallback = initialPopupProxyCallback;
        }
    }, []);

    const loadClients = async (input, callback) => {
		let clients = await ClientsService.list({
			search: input,
			no_paginate: true
		});

		let formatted = clients?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

    const openPopupWindowFix = (e) => {
        e.preventDefault();

        let href = e.currentTarget.href;

        openPopupWindow(href, 'client_popup');
    }

    return (
        <LabeledFrame 
            label={
                <ClientsSelector>
                    <EmpoweredSelector
                        label="Cliente"
                        load={(input, callback) => loadClients(input, callback)}
                        onChange={(value) => setData(value)}
                        timeout={250}
                        value={data?.id}
                        disabled={!canEdit} 
                        noRemovable={true}
                        dropDownMenuWidth={'250px'}
                    />

                    { (data?.id && canEdit) && 
                        <button onClick={() => setData(null)} className="text-danger"><i className="bi bi-x"></i></button>
                    }

                    { (!data?.id && canEdit) && 
                        <NavLink to="/clients/add?popup=true" className="ms-3 text-primary" onClick={(e) => openPopupWindowFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
                    }
                </ClientsSelector>
            }
            className="mb-3 mb-md-0"
        >
            <div className="row">
                { errors['client_id'] &&
                    <div className="invalid-feedback d-block mb-2 mt-0">{ errors['client_id'][0] }</div>
                }

                <div className="col col-static-250">
                    <div className="mb-2">
                        <CustomInput 
                            label="Razón social" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('name', e.target.value)} 
                            value={data?.name ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['client_data.name'] &&
                            <div className="invalid-feedback d-block">{ errors['client_data.name'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-120">
                    <div className="mb-2">
                        <CustomInput 
                            label="CIF" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('vatnumber', e.target.value)} 
                            value={data?.vatnumber ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['client_data.vatnumber'] &&
                            <div className="invalid-feedback d-block">{ errors['client_data.vatnumber'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-300">
                    <div className="mb-2">
                        <CustomInput 
                            label="Dirección" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('address', e.target.value)} 
                            value={data?.address ?? ''}
                            readOnly={!canEdit}
                        />
                        { errors['client_data.address'] &&
                            <div className="invalid-feedback d-block">{ errors['client_data.address'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-120">
                    <div className="mb-2">
                        <CustomInput 
                            label="C.P." 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('postalcode', e.target.value)} 
                            value={data?.postalcode ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['client_data.postalcode'] &&
                            <div className="invalid-feedback d-block">{ errors['client_data.postalcode'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-200">
                    <div className="mb-2">
                        <CustomInput 
                            label="Ciudad" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('city', e.target.value)} 
                            value={data?.city ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['client_data.city'] &&
                            <div className="invalid-feedback d-block">{ errors['client_data.city'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-180">
                    <div className="mb-2">
                        <CustomInput 
                            label="Provincia" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('province', e.target.value)} 
                            value={data?.province ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['client_data.province'] &&
                            <div className="invalid-feedback d-block">{ errors['client_data.province'][0] }</div>
                        }
                    </div>
                </div>
                <div className="col col-static-120">
                    <div className="mb-2">
                        <CustomInput 
                            label="País" 
                            type="text" 
                            className="form-control form-control-sm" 
                            onChange={(e) => setDataField('country', e.target.value)} 
                            value={data?.country ?? ''} 
                            readOnly={!canEdit}
                        />
                        { errors['client_data.country'] &&
                            <div className="invalid-feedback d-block">{ errors['client_data.country'][0] }</div>
                        }
                    </div>
                </div>
            </div>	
        </LabeledFrame>
    );
}