import React, { useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { loader } from "helpers/generic";
import Modal from "./CategoryModal";

const StyledRow = styled.tr`
    &:hover {

        td {
            background: var(--bs-gray-100) !important;
        }
    }

    &.is-parent {

        td {
            background: var(--bs-gray-200) !important;

            &:first-child {
                font-weight: bold;
            }
        }
    }

    td {
        position: relative;
        height: 25px;
        vertical-align: middle;

        &:first-child {
            position: sticky;
            left: 0;
            background: var(--bs-gray-100);
            z-index: 1;
            font-weight: 400;
            text-align: left;
            padding-left: 5px;
            padding-right: 20px;
            line-height: 100%;

            button {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0;
                background: transparent;
                color: var(--bs-primary);

                i {
                    font-size: 10px;
                }

                &:hover {
                    transform: scale(1.2);
                }
            }
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            background: transparent !important;
            border: 0;
            padding: 0;
            outline: none;

            &.with-errors {
                background: var(--bs-danger) !important;
                color: white;
            }
        }

        &.real_wrapper {
            position: relative;
            padding: 0;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 20px;
            }
        }
    }
`;

const Category = (props) => {
    const category = props.category;
    const data = props.data;
    const setData = props.setData;
    const realAmounts = props.realAmounts ?? [];
    const canEdit = props.canEdit;
    const months = props.months;
    const accumulated = props.accumulated;
    const errors = props.errors;

    const isChild = category.is_used || (category.parent && category.children_count === 0);

    const [modalEditOpened, setModalEditOpened] = useState(false);

    const setField = (field, value) => {
        const newData = {...data};

        newData.category_id = category.id;
        newData.type = category.type;

        newData[field] = value;
        setData(newData);
    }

    const setMonthAmount = (idx, value) => {
        const newData = {...data};

        newData.category_id = category.id;
        newData.type = category.type;

        if ( !newData.monthsAmount || Object.keys(newData.monthsAmount).length === 0 ) {
            newData['monthsAmount'] = {};
            for(let i=1; i<=12; i++) newData['monthsAmount'][i] = {amount: ''}
        }
        
        newData.monthsAmount[idx].amount = value;
        setData(newData);
    }
    
    return (
        <>
            <StyledRow className={isChild ? 'is-child' : 'is-parent'}>
                <td>
                    <div className="d-flex w-100">
                        {category.parent && 
                            <>
                                <button onClick={() => setModalEditOpened(true)}><i className="bi bi-calendar-week"></i></button>                
                                <div style={{width: (category.level * 10) + 'px', textAlign: 'right', paddingRight: '5px'}}>
                                    <i className="bi bi-arrow-return-right"></i>
                                </div>
                            </>
                        }
                        {category.name ?? ''}
                    </div>
                </td>
                { !isChild ?
                    <td colSpan={'100%'}></td>
                    :
                    <>
                        <td>
                            <input type="number" className={'no-arrows ' + (errors && errors['day'] ? 'with-errors' : '')} value={data?.day ?? ''} onChange={(e) => setField('day', e.target.value)} readOnly={!canEdit} />
                        </td>
                        {months.map(month => {
                            let amount = data && data.monthsAmount && data.monthsAmount[month] ? data.monthsAmount[month].amount : '';
                            let real = realAmounts[month] !== undefined ? parseFloat(realAmounts[month]) : undefined;
                            let diff = parseFloat((real ?? 0) - (amount ?? 0));

                            return (
                                <React.Fragment key={month}>
                                    <td>
                                        <input type="number" className={'no-arrows ' + (errors && errors['monthsAmount.' + month + '.amount'] ? 'with-errors' : '')} value={amount} onChange={(e) => setMonthAmount(month, e.target.value)} readOnly={!canEdit} />       
                                    </td>
                                    <td className="real_wrapper">
                                        {real !== undefined && <input type="number" className="no-arrows" value={real.toFixed(2)} readOnly />}
                                        {(real === undefined && data?.id > 0) && loader}
                                    </td>
                                    <td>
                                        <input type="number" className="no-arrows" value={diff.toFixed(2)} readOnly />
                                    </td>
                                </React.Fragment>
                            );
                        })}
                        <td className="accumulated">
                            <input type="number" className="no-arrows" value={parseFloat(accumulated?.prevision ?? 0).toFixed(2)} readOnly />
                        </td>
                        <td className="accumulated">
                            <input type="number" className="no-arrows" value={parseFloat(accumulated?.real ?? 0).toFixed(2)} readOnly />
                        </td>
                        <td className="accumulated">
                            <input type="number" className="no-arrows" value={parseFloat(accumulated?.diff ?? 0).toFixed(2)} readOnly />
                        </td>
                    </>
                }
            </StyledRow>

            {modalEditOpened && createPortal(
                <Modal 
                    data={data}
                    closeCallback={(data) => {
                        if (data) {
                            setData(data);
                        }
                        
                        setModalEditOpened(false);
                    }}
                />, 
                document.body
            )}
        </>
    );
}

// export default Category;
export default React.memo(Category);