import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import { downloadFile, formatNumber } from 'helpers/generic';
import { authUserPermission } from 'helpers/business';
import { default as PaymentMethodsService } from 'services/PaymentMethods';
import { default as TradeCategoriesService } from 'services/TradeCategories';
import { default as ExpensesService } from 'services/Expenses';
import { default as ProvidersService } from 'services/Providers';
import { default as FilesService } from 'services/Files';
import BusinessChecker from 'components/BusinessChecker';
import BulkActionsDropdown from './BulkActionsDropdown';

const Table = styled.table`
	tr {
		&:not(.details) {
			td {
				&:nth-child(1) {
					vertical-align: top !important;

					& > input {
						margin-top: 4px;
					}
				}
			}
			
			th, td {
				&:nth-child(1) {
					width: 15px;
					pointer-events: none;
					vertical-align: middle;

					& > input {
						pointer-events: initial;
					}
				}
			
				&:nth-child(2) {
					width: 80px;
					white-space: nowrap;

					& > div {
						white-space: wrap;
						color: var(--bs-primary);
						font-size: 11px;
						line-height: 11px;
					}
				}

				&:nth-child(3) {
					width: 100px;
					white-space: nowrap;
				}

				&:nth-child(5) {
					width: 100px;
					text-align: right;
					white-space: nowrap;
				}

				&:nth-child(6) {
					width: 180px;
					text-align: right;
					white-space: nowrap;
				}

				&:nth-child(7) {
					width: 80px;
					text-align: center;
				}

				&:nth-child(8) {
					width: 30px;
					text-align: center;
					
					i {
						&::before {
							transform: scale(1.5);
						}
					}
				}


				@media (max-width: 768px) {
					&:nth-child(5),
					&:nth-child(6),
					&:nth-child(7) {
						display: none;
					}
				}
			}
		}
	}
`;

let searchTimeout = null;

export default function Expenses() {
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const searchQS = queryParams.get('search') ?? undefined;

	let [forceReload, _setForceReload] = useState(null);
	let setForceReload = (value) => {
		_setForceReload(value);
		setSelected([]);
	}
	let [expenses, setExpenses] = useState({});
	let [selected, setSelected] = useState([]);
	let [search, setSearch] = useState(searchQS);
	let [dateFrom, setDateFrom] = useState( moment().startOf('year').format('YYYY-MM-DD') );
	let [dateTo, setDateTo] = useState( moment().endOf('year').format('YYYY-MM-DD') );
	let [category, setCategory] = useState({
		id: null,
		name: null
	});
	let [provider, setProvider] = useState({
		id: null,
		name: null
	});
	let [paymentmethod, setPaymentmethod] = useState({
		id: null,
		name: null
	});
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('date');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setExpenses({...expenses, data: undefined});
		_setPage(page);
	}
	let [paymentStatus, setPaymentStatus] = useState(null);


	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	useEffect(() => {
		const getExpenses = async () => {
			let expenses = await ExpensesService.list({
				page: 				page,
				search: 			search,
				category_id: 		category?.id,
				paymentmethod_id: 	paymentmethod?.id,
				provider_id: 		provider?.id,
				sort: 				sortField,
				direction: 			sortDirection,
				paid_status: 		paymentStatus,
				date_from: 			dateFrom,
				date_to: 			dateTo,
			});

			setExpenses({...expenses});
			setSkeletonRows(expenses?.data?.length);
		}

		getExpenses();
	}, [page, search, category, paymentmethod, provider, sortField, sortDirection, paymentStatus, dateFrom, dateTo, forceReload]);

	const loadCategories = async (input, callback) => {
		let categories = await TradeCategoriesService.list('expenses', {
			search: input,
			no_paginate: true,
		});
		
		let formatted = categories?.map((el) => {
			return {
				value: el, 
				label: (
					<div style={{paddingLeft: ((el.level-1) * 8) + 'px'}}>
						{el.level > 1 &&
							<>
								<i className="bi bi-arrow-return-right"></i>
								&nbsp;
							</>
						}
						<span className="fw-bold text-dark me-1">{el.code}</span>
						{el.name}
					</div>
				)
			};
		});
		callback(formatted);
	}

	const loadProviders = async (input, callback) => {
		let providers = await ProvidersService.list({
			search: input,
			no_paginate: true,
		});
		
		let formatted = providers?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const loadPaymentmethods = async (input, callback) => {
		let pmethods = await PaymentMethodsService.list({
			search: input,
			no_paginate: true,
		});
		
		let formatted = pmethods?.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const setDatesRange = (e, range) => {
		e.preventDefault();

		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'firstquarter':
				dateFrom = moment().set('month', 0).startOf('month').format('YYYY-MM-DD');
				dateTo = moment().set('month', 2).endOf('month').format('YYYY-MM-DD');
			break;

			case 'secondquarter':
				dateFrom = moment().set('month', 3).startOf('month').format('YYYY-MM-DD');
				dateTo = moment().set('month', 5).endOf('month').format('YYYY-MM-DD');
			break;

			case 'thirdquarter':
				dateFrom = moment().set('month', 6).startOf('month').format('YYYY-MM-DD');
				dateTo = moment().set('month', 8).endOf('month').format('YYYY-MM-DD');
			break;

			case 'fourthquarter':
				dateFrom = moment().set('month', 9).startOf('month').format('YYYY-MM-DD');
				dateTo = moment().set('month', 11).endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;
			default: 
		}

		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const download = async (e, expense) => {
		e.stopPropagation();

		let result = await FilesService.download(expense.file.id);
		downloadFile(result, expense.file.name);
	}

	const toggleCheckbox = (rowNumber) => {
		let newSelected = [...selected];

		let idx = newSelected.indexOf(rowNumber);
		if ( idx === - 1 ) newSelected.push(rowNumber);
		else newSelected.splice(idx, 1);

		setSelected(newSelected);
	}

	const toggleAllCheckbox = () => {
		if ( selected.length === expenses.data?.length ) setSelected([]);
		else setSelected(expenses.data?.map((el, idx) => idx));
	}

	const deleteExpense = async (e, expense) => {
		e.preventDefault();
		e.stopPropagation();

		const c = window.confirm('¿Seguro que quieres eliminar el gasto ' + expense.number + '?');
		if ( !c ) return;

		let result = ExpensesService.delete(expense.id);
		if ( result ) {
			toast.info('Gasto borrado')
			setForceReload(Math.random());
			document.querySelector('body').click(); // Fix to close opened dropdown
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<BusinessChecker />
			<section>
				<div className="page-title">
					<h1>Gastos</h1>
					
					{ authUserPermission('add') &&
						<NavLink to={'/expenses/add' + (popup ? '?popup=true' : '')} className="btn btn-sm btn-light ms-auto">Nuevo gasto</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadCategories}
										onChange={(value) => setCategory({id: value?.id, name: value?.name, code: value?.code})}
										timeout={250}
										label={
											category?.id ?
												<div>
													<b className="me-1">{category.code}</b>  
													<span>{category.name}</span>
												</div>
												: 
												null
										}
										placeholder="- Categoría -"
										value={category?.id}
									/>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadPaymentmethods}
										onChange={(value) => setPaymentmethod({id: value?.id, name: value?.name})}
										timeout={250}
										label={paymentmethod?.name}
										placeholder="- Método de pago -"
										value={paymentmethod?.id}
									/>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={paymentStatus ?? ''} onChange={(e) => setPaymentStatus(e.target.value)}>
										<option value="">- Estado del pago -</option>
										<option value="paid">Pagado</option>
										<option value="partial">Parcial</option>
										<option value="unpaid">No pagado</option>
									</select>
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fecha
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
										<span className="input-group-btn">
											<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
											<ul className="dropdown-menu dropdown-menu-end">
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'thismonth')}>Este mes</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'lastmonth')}>Mes pasado</a></li>
												<li><hr className="dropdown-divider" /></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'firstquarter')}>Primer trimestre</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'secondquarter')}>Segundo trimestre</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'thirdquarter')}>Tercer trimestre</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'fourthquarter')}>Cuarto trimestre</a></li>
												<li><hr className="dropdown-divider" /></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'thisyear')}>Este año</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'lastyear')}>Año pasado</a></li>
											</ul>
										</span>
									</div>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} value={searchQS} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0">
									<EmpoweredSelector
										load={loadProviders}
										onChange={(value) => setProvider({id: value?.id, name: value?.name})}
										timeout={250}
										label={provider?.name}
										placeholder="- Proveedor -"
										value={provider?.id}
									/>
								</div>
								{selected.length > 0 &&
									<div className="col-md-2 mb-2 mt-2 mb-md-0">
										<BulkActionsDropdown 
											selectedItems={expenses.data?.map((el, idx) => selected.indexOf(idx) !== -1 ? el : null).filter(el => el != null)}
											parentForceReload={() => {
												setSelected([]);
												setForceReload(Math.random());
											}}
										/>
									</div>
								}
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>
												<input 
													type="checkbox"
													onChange={() => toggleAllCheckbox()} 
													checked={expenses.data?.length > 0 && selected.length === expenses.data?.length} 
												/>
											</th>
											<ThSortable direction={sortDirection} active={sortField === 'number'} onClick={() => sortTableClick('number')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'provider.name'} onClick={() => sortTableClick('provider.name')}>Proveedor</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'base'} onClick={() => sortTableClick('base')}>Base</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'total'} onClick={() => sortTableClick('total')}>Total</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'paid_status'} onClick={() => sortTableClick('paid_status')}>Pagado</ThSortable>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ expenses.data?.length > 0 &&
											expenses.data.map((el, idx) => {
												return ( 
													<React.Fragment key={'p'+idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>
																<input 
																	type="checkbox" 
																	onClick={(e) => e.stopPropagation()} 
																	onChange={(e) => toggleCheckbox(idx)} 
																	checked={selected.indexOf(idx) !== -1} 
																/>
															</td>
															<td>{ el.number }</td>
															<td>{ el.date ? moment(el.date).format('DD-MM-YYYY') : '-' }</td>
															<td>
																{el.provider?.name ?? '-'}
																<div><small>{el.provider?.vatnumber}</small></div>
															</td>
															<td>{ formatNumber(el.base) } €</td>
															<td>{ formatNumber(el.total) } €</td>
															<td>
																{ el.paid_status === 'full' && <span className="badge bg-success">Si</span>}
																{ el.paid_status === 'unpaid' && <span className="badge bg-danger">No</span>}
																{ el.paid_status === 'partial' && <span className="badge bg-indigo">Parcial</span>}
															</td>
															<td>
																<div className="dropdown">
																	<button className="btn btn-sm btn-table p-0 px-1 text-primary" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
																		<i className="bi bi-three-dots-vertical"></i>
																	</button>
																	<ul className="dropdown-menu dropdown-tablerow-actions">
																		{ authUserPermission('edit') &&
																			<li>
																				<NavLink className="dropdown-item" to={'/expenses/edit/' + el.id + (popup ? '?popup=true' : '')}>
																					<i className="bi bi-pencil"></i>&nbsp;
																					Editar
																				</NavLink>
																			</li>
																		}
																		{/* { authUserPermission('add') &&
																			<li>
																				<NavLink className="dropdown-item" to={'/expenses/add?copy_from_id=' + el.id + '&edit=true' + (popup ? '&popup=true' : '')}>
																					<i className="bi bi-cash"></i>&nbsp;
																					Copiar
																				</NavLink>
																			</li>
																		} */}
																		{el.file_id &&
																			<>
																				<li><hr className="dropdown-divider" /></li>
																				<li>
																					<button className="dropdown-item" onClick={(e) => download(e, el)}>
																						<i className="bi bi-download me-1"></i>&nbsp;
																						Descargar
																					</button>
																				</li>
																			</>
																		}
																		<li><hr className="dropdown-divider" /></li>
																		{ authUserPermission('delete') &&
																			<li>
																				<NavLink className="dropdown-item" to={'delete-expense-' + el.id} onClick={(e) => deleteExpense(e, el)}>
																					<i className="bi bi-x"></i>&nbsp;
																					Eliminar
																				</NavLink>
																			</li>
																		}
																	</ul>
																</div>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-6">
																			<div><b className="sbold">Proveedor</b>: {el.provider?.name ?? '-'}</div>
																			<div><b className="sbold">Nº</b>: {el.number ?? '-'}</div>
																			<div className="mb-3"><b className="sbold">Fecha</b>: {el.date ? moment(el.date).format('DD-MM-YYYY') : '-'}</div>

																			<div><b className="sbold">Base imponible</b>: { formatNumber(el.base) } €</div>
																			<div><b className="sbold">Total</b>: { formatNumber(el.total) } €</div>
																			<div>
																				<b className="sbold">Pagado</b>:&nbsp; 
																				{ el.paid_status === 'full' && <span className="badge bg-success">Si</span>}
																				{ el.paid_status === 'unpaid' && <span className="badge bg-danger">No</span>}
																				{ el.paid_status === 'partial' && <span className="badge bg-indigo">Parcial</span>}
																			</div>
																		</div>
																		<div className="col-md-6">
																			<div><b className="sbold">Forma de pago</b>: {el.paymentmethod_data?.name ?? '-'}</div>
																			<div><b className="sbold">Categoría</b>: {el.category?.name ?? '-'}</div>
																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ expenses.data && !expenses.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ expenses.data === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
									<tfoot>
										<tr className="sbold">
											<td colSpan="5"></td>
											<td className="text-end">{ formatNumber(expenses.total_sum) } €</td>
											<td colSpan="2"></td>
										</tr>
									</tfoot>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={expenses?.current_page}
									max={expenses?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}


