import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { isTouchEnabled } from 'helpers/generic';
import useStore from "store";
import { getBusiness } from 'helpers/business';
import { getFromSession, setToSession } from 'helpers/session';

const SidebarStyled = styled.aside`
	--top: ${props => props.top}px;
	--left: ${props => props.left};
	--desktop-width-notvisible: 80px;
	--opened-width: 250px;

	position: fixed;
	top: var(--top);
	left: var(--left);
	z-index: 20;
	height: calc(100% - var(--top));
	width: 100%;
	@media (min-width: 993px) {
	    transition: all 0.3s;
	}
	@media (max-width: 992px) {
	    box-shadow: none !important;
	}

	.sidebar-cristal {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	ul.sidebar-menu {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		width: 250px;
		height: 100%;
		background: white;
		padding: 15px;
		overflow-y: auto;
    	box-shadow: 0px 0px 10px rgb(1 41 112 / 10%);
    	position: relative;
    	z-index: 2;
    	transition: all 0.3s;

		& > li {
			margin: 10px 0;

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			&.submenu {

				button {
					--arrowWidth: 0.6rem;

					position: relative;

					&:after {
						content: "";
						display: block;
						position: absolute;
						right: 20px;
						top: calc(50% - (var(--arrowWidth) / 2));
						height: var(--arrowWidth);
						width: var(--arrowWidth);
						border-right: 1px solid #5e6e82;
						border-bottom: 1px solid #5e6e82;
						transform: rotate(45deg);
						transition: all 0.1s ease-in-out;
						transform-origin: center;
						transition-property: transform, border-color;
					}
				}

				&.opened {

					&:has(.active) {

						button {
							color: var(--bs-blue);
						}
					}

					button {

						&:after {
							transform: translateY(50%) rotate(225deg);
						}
					}

					ul {
						height: auto;
						overflow: visible;
						transform: scaleY(1);
						height: auto;
						overflow: visible;
					}
				}

				ul {
					list-style: none;
					padding-left: 0;
					transform: scaleY(0);
					transform-origin: top;
					height: 0;
					overflow: hidden;

					li {
						margin: 5px 0;
						display: flex;

						a {
							display: inline-flex;
							text-decoration: none;
							font-size: 14px;
							color: var(--bs-body-color);
							padding: 5px 10px;
							margin-left: 30px;
							width: 100%;

							&.active {
								border-radius: 5px;
								background: var(--bs-blue);
								color: white;
							}
						}
					}
				}
			}

			& > a, 
			& > button {
				display: flex;
				align-items: center;
				text-decoration: none;
				color: var(--bs-body-color);
				padding: 10px 15px;
				transition: all 0.3s;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				user-select: none;
				background: transparent;
				border-radius: 5px;
				border: 0;
				width: 100%;

				i {
					width: 30px;
					text-align: left;
				}

				&.active {
					background: var(--bs-blue);
					color: white;
				}

				&.disabled {
					pointer-events: none;
					background: #f6f9ff;
					opacity: 0.6;
				}

				.badge {
					font-size: 9px;
					margin-left: 5px;
					display: inline-flex;
					justify-content: center;
					align-items: center;
				}
			}

			a, button {
				&:hover:not(.active) {
					background: #f6f9ff;
					color: var(--bs-blue);
				}
			}
		}
	}

	// Mobile
    @media (max-width: 992px) {
    	width: 100%;

    	&:not(.visible) {
    		left: -100%;
    	}
    }

    // Desktop
    @media (min-width: 993px) {
    	width: var(--desktop-width-notvisible);

    	&:not(.visible) {
    		& > ul {
    			width: var(--desktop-width-notvisible);

    			& > li > a > span {
    				display: none;
    			}

    			&:hover {
		    		width: var(--opened-width) !important;
		    		overflow-x: hidden;

		    		li > a > span {
		    			display: inherit;
		    		}
		    	}
    		}
    		&.is-touch-screen {
    			& > ul {
		    		display: none;
    			}

    			&.sidebar-cristal {
    				display: none;
    			}
	    	}
    	}
    }
`;

let hoverFix = false; // Needed to prevent close on re-render for desktop

const Sidebar = (props) => {
	const location = useLocation();
    const dispatch = useStore(state => state.dispatch);
    const sidebarVisible = useStore(state => state.sidebarVisible);
    const [hover, _setHover] = useState(hoverFix);
	const [submenusOpened, _setSubmenusOpened] = useState(getFromSession('sidebarSubmenusOpened') ?? {});
	const setSubmenusOpened = (submenusOpened) => {
		_setSubmenusOpened(submenusOpened);
		setToSession('sidebarSubmenusOpened', submenusOpened);
	}	

    const setHover = (status) => {
    	if ( !isTouchEnabled() ) hoverFix = status;
    	_setHover(status);
    }
	
    const setSidebarVisible = useCallback((status) => {
    	setHover(status);
	    dispatch({
			type: 'setSidebarVisible',
			status: status
		});
    }, [dispatch]);

	const toggleSubmenu = (name) => {
		if ( name === undefined ) return;

		let submenusOpenedCopy = {...submenusOpened};
		if ( submenusOpenedCopy[name] ) delete submenusOpenedCopy[name];
		else submenusOpenedCopy[name] = true;
		setSubmenusOpened(submenusOpenedCopy);
	}

    // Always hide sidebar on mobile url change
    useEffect(() => {
    	if ( isTouchEnabled() ) {
    		setSidebarVisible(false);
    	}
    }, [location, setSidebarVisible]);

	// Set submenu button active is child active
	useEffect(() => {
		const activeSubmenuList = document.querySelectorAll('.sidebar-menu .submenu .active');

		let submenuOpened = getFromSession('sidebarSubmenusOpened') ?? {};

		activeSubmenuList.forEach(el => {
			let submenu = el.closest('.submenu');
			let name = submenu.dataset.submenu;
			submenuOpened[name] = true;
		});

		if ( Object.keys(submenuOpened).length > 0 ) {
			setSubmenusOpened(submenuOpened);
		}
	}, []);

	// Scroll top
	useEffect(() => {
		const sidebarMenu = document.querySelector('#sidebar ul.sidebar-menu');

		const lastScrollTop = getFromSession('sidebarLastScrollTop');
		if ( lastScrollTop ) {
			setTimeout(() => {
				sidebarMenu.scrollTop = lastScrollTop;
			}, 0);
		}
	}, []);

	const business = getBusiness();

	let businessSelected = business?.id ? true : false;

	return (
		<SidebarStyled 
			className={(sidebarVisible || hover ? 'visible' : '') + ' ' + (isTouchEnabled() ? 'is-touch-screen' : '')} 
			onMouseEnter={(e) => setHover(true)} 
			onMouseLeave={(e) => setHover(false)}
			top={props.top}
			left={props.left}
			id="sidebar"
		>
			<div className="sidebar-cristal" onClick={() => setSidebarVisible(false)}></div>
			<ul 
				className="sidebar-menu"
				onScroll={(e) => setToSession('sidebarLastScrollTop', e.target.scrollTop)}
			>
				<li>
					<NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>
						<i className="bi bi-clipboard-data"></i> 
						<span>Dashboard</span>
					</NavLink>
				</li>

				<li>
					<NavLink 
						to="/previsions" 
						className={({ isActive }) => {
							return (isActive ? 'active' : '') + (!businessSelected ? 'disabled' : '');
						}}
					>
						<i className="bi bi-cash"></i> 
						<span>Previsiones</span>
					</NavLink>
				</li>	

				<li className={'submenu ' + (submenusOpened['sales'] && businessSelected ? 'opened' : 'closed')}>
					<button onClick={(e) => toggleSubmenu(e.currentTarget.dataset.submenu)} data-submenu="sales" className={!businessSelected ? 'disabled' : ''}>
						<i className="bi bi-receipt"></i> 
						Ventas
					</button>

					<ul>
						<li>
							<NavLink 
								to="/clients" 
								className={({ isActive }) => {
									return (isActive ? 'active' : '') + (!businessSelected ? 'disabled' : '');
								}}
							>
								Clientes
							</NavLink>
						</li>	
						<li>
							<NavLink 
								to="/invoices" 
								className={({ isActive }) => {
									return (isActive ? 'active' : '') + (!businessSelected ? 'disabled' : '');
								}}
							>
								Facturas
							</NavLink>
						</li>
					</ul>
				</li>
				
				<li>
					<NavLink 
						to="/treasury" 
						className={({ isActive }) => {
							return (isActive ? 'active' : '') + (!businessSelected ? 'disabled' : '');
						}}
					>
						<i className="bi bi-currency-euro"></i> 
						<span>Tesorería</span>
					</NavLink>
				</li>	
				<li>
					<NavLink 
						to="/receipts" 
						className={({ isActive }) => {
							return (isActive ? 'active' : '') + (!businessSelected ? 'disabled' : '');
						}}
					>
						<i className="bi bi-map"></i> 
						<span>Recibos</span>
					</NavLink>
				</li>	
				<li>
					<NavLink 
						to="/bankremittances" 
						className={({ isActive }) => {
							return (isActive ? 'active' : '') + (!businessSelected ? 'disabled' : '');
						}}
					>
						<i className="bi bi-bank"></i> 
						<span>Remesas bancarias</span>
					</NavLink>
				</li>	

				<li className={'submenu ' + (submenusOpened['buys'] && businessSelected ? 'opened' : 'closed')}>
					<button onClick={(e) => toggleSubmenu(e.currentTarget.dataset.submenu)} data-submenu="buys" className={!businessSelected ? 'disabled' : ''}>
						<i className="bi bi-bag"></i> 
						Compras
					</button>

					<ul>
						<li>
							<NavLink 
								to="/providers" 
								className={({ isActive }) => {
									return (isActive ? 'active' : '');
								}}
							>
								Proveedores
							</NavLink>
						</li>	
						<li>
							<NavLink 
								to="/providers-invoices" 
								className={({ isActive }) => {
									return (isActive ? 'active' : '');
								}}
							>
								Facturas
							</NavLink>
						</li>	
						<li>
							<NavLink 
								to="/expenses" 
								className={({ isActive }) => {
									return (isActive ? 'active' : '');
								}}
							>
								Gastos
							</NavLink>
						</li>	
					</ul>
				</li>

				
				<li>
					<NavLink 
						to="/bank-reconciliation" 
						className={({ isActive }) => {
							return (isActive ? 'active' : '') + (!businessSelected ? 'disabled' : '');
						}}
					>
						<i className="bi bi-arrow-left-right"></i> 
						<span>Conciliación bancaria</span>
					</NavLink>
				</li>	

				<li className={'submenu ' + (submenusOpened['balances'] && businessSelected ? 'opened' : 'closed')}>
					<button onClick={(e) => toggleSubmenu(e.currentTarget.dataset.submenu)} data-submenu="balances" className={!businessSelected ? 'disabled' : ''}>
						<i className="bi bi-bar-chart-fill"></i> 
						Balances
					</button>

					<ul>
						<li>
							<NavLink 
								to="/balances/general" 
								className={({ isActive }) => {
									return (isActive ? 'active' : '');
								}}
							>
								General
							</NavLink>
						</li>	
					</ul>
				</li>	
			</ul>
		</SidebarStyled>
	);
};

export default Sidebar;