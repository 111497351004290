import React from 'react';
import styled from 'styled-components';
import { formatNumber } from 'helpers/generic';
import LabeledFrame from 'components/LabeledFrame';
import moment from 'moment';

const Table = styled.table`
	font-size: 13px;

	thead {
		th {
			font-weight: 500;
		}
	}

	td, th {
		&:nth-child(2),
		&:nth-child(3) {
			width: 100px;
			text-align: right;
		}
	}
`;

const TableWrapper = styled.div`
	position: relative;
`;

export default function Payments(props) {
	const data = props.data;

	const total = data.total;
	const payments = data.payments ?? [];
	const paid = payments?.reduce((carry, item) => carry += item ? Math.abs(item.amount) : 0, 0) ?? 0;

	return (
		<LabeledFrame label={'Pagos'}>
			<TableWrapper>
				<Table className="table table-sm table-bordered mb-0">
					<thead>
						<tr>
							<th>Concepto</th>
							<th>Fecha</th>
							<th>Importe</th>
						</tr>
					</thead>
					<tbody>
						{ data.payments && data.payments.map((el, idx) => {
							if ( !el ) return;

							return (
								<tr key={idx}>
									<td>{el.concept}</td>
									<td>{moment(el.date).format('DD-MM-YYYY')}</td>
									<td>{formatNumber(Math.abs(el.amount))} €</td>
								</tr>
							);
						})}
						{ (!data.payments || !data.payments.length) && <tr><td colSpan="100%" className="p-2">No se han encontrado pagos</td></tr> }
					</tbody>
					<tfoot>
						<tr>
							<td>Pendiente</td>
							<td></td>
							<td>{total - paid > 0 ? formatNumber(total - paid, 2) : 0} €</td>
						</tr>
					</tfoot>
				</Table>
			</TableWrapper>
		</LabeledFrame>
	);
}