import axios from 'axios';

export default class Files {

	static axiosCancelToken = null;

	static getFolderTree = (folderId, businessId) => {
		this.newAxiosCancelToken();

		return axios.get('/api/documents/get-folder-tree', {
			params: {
				folder_id: folderId ?? null,
				business_id: businessId ?? null
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	static saveFolder = (folderId, name, parentFolderId, business_id) => {
		this.newAxiosCancelToken();

		return axios.post('/api/documents/save-folder' + (folderId && folderId !== -1 ? '/' + folderId : ''), {
			name: name,
			business_id: business_id,
			parent_id: parentFolderId && parentFolderId !== -1 ? parentFolderId : null
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data.status ? true : false;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) return error.response.data.errors;
			return false;
		});	
	}

	static saveFile = (file_id, name, folderId, business_id) => {
		this.newAxiosCancelToken();

		return axios.post('/api/documents/save-file' + (file_id && file_id !== -1 ? '/' + file_id : ''), {
			name: name,
			business_id: business_id ?? null,
			folder_id: folderId
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data.status ? true : false;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) return error.response.data.errors;
			return false;
		});	
	}

	static deleteFile = (file_id) => {
		this.newAxiosCancelToken();

		return axios.post('/api/documents/delete-file/' + file_id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data.status ? true : false;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			return false;
		});	
	}

	static deleteFolder = (folder_id) => {
		this.newAxiosCancelToken();

		return axios.post('/api/documents/delete-folder/' + folder_id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data.status ? true : false;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			return false;
		});	
	}

	static getFolder = (folder_id, business_id = null) => {
		this.newAxiosCancelToken();

		let params = {};
		if ( business_id ) params.business_id = business_id;

		return axios.get('/api/documents/get-folder/' + folder_id, {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});	
	}

	static move = (file_id, folder_id) => {
		this.newAxiosCancelToken();

		return axios.post('/api/documents/change-file-folder/' + file_id, {
			folder_id: folder_id,
		}, {
			cancelToken: this.axiosCancelToken.token
		})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			if ( axios.isCancel(error) ) return;
			return false;
		});	
	}

	static copy = (file_id, folder_id) => {
		this.newAxiosCancelToken();

		return axios.post('/api/documents/copy-file-folder/' + file_id, {
			folder_id: folder_id,
		}, {
			cancelToken: this.axiosCancelToken.token
		})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			if ( axios.isCancel(error) ) return;
			return false;
		});	
	}

	static upload = (file, folder_id = null, business_id = null, onUploadProgress = null) => {
		this.newAxiosCancelToken();

		let formData = new FormData();
		formData.append('file', file);
		formData.append('folder_id', folder_id);
		formData.append('business_id', business_id);

		return axios.post('/api/documents/upload-file', formData, {
			headers: {
				'Content-Type': 'multipart/form-data' 
			},
			cancelToken: this.axiosCancelToken.token,
			onUploadProgress: (e) => onUploadProgress ? onUploadProgress(e) : null
		})
		.then((response) => {
			return true;
		})
		.catch((error) => {
			if ( axios.isCancel(error) ) return;
			
			return error.response?.data?.message ?? 'error indefinido';
		});
	}

	static download = (id) => {
		this.newAxiosCancelToken();

		return axios.get('/api/documents/download-file/' + id, {
			responseType: 'blob',
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}